<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">朱墨时序鉴真伪，却是老赖“挖坑”埋了自己！</div>
      <p class="time">2019.01.24</p>
      <p class="space_content" >
        案件详情：
      </p>
      <p class="space_content" >
        卢某在当地的石材厂从事开叉车的工作，某日，他以要买二手叉车缺少资金为由向杜某借款15万元，借款期限为4个月，月利率5%，并出具一份借款收条给杜某。当借款期限届满后，卢某向杜某借款的本金及利息均未偿还。杜某多次联系卢某沟通无果。无奈之下，杜某将卢某告上法庭，并提供对方签字按捺的收条作为证据提供给法庭。
      </p>
      <p class="space_content" >
        但卢某表示自己从未出具过任何收条给杜某，称此收条是杜某在加盖有自己指纹捺印的空白纸上套打而成的，认为该收条是杜某伪造而成的。为了证明自己的主张，卢某主动向法院申请对收条上打印的部分字迹与捺印指纹形成的先后顺序进行司法鉴定，即朱墨时序鉴定。
      </p>
      <p class="space_content" >
        鉴定过程：
      </p>
      <p class="space_content" >
        法院委托福建历思司法鉴定所泉州分所（以下简称泉州分所）进行鉴定，泉州分所接受委托后，确认了该收条是符合检测条件的，并依据SF/Z JD0201001-2010文书鉴定通用规范、SF/Z JD0201007-2010朱墨时序鉴定规范进行鉴定。
      </p>
      <p class="space_content" >
        1、表观检验
      </p>
      <p class="space_content" >
        对检材进行直观检验，其所用纸张为A4规格打印/复印用纸裁切而成，纸张上侧存在明显的撕切痕；其上内容由黑色打印字迹、黑色墨水手写笔迹及红色印油捺印指纹所组成；其待检的捺印指纹与打印字迹交叉重叠部位周围印迹和文字的墨迹分布均匀、完整，符合鉴定要求。
      </p>
      <p class="space_content" >
        2、微观检验
      </p>
      <p class="space_content" >
        将检材置于SZX-16型奥林巴斯研究级体式显微镜下观察检验，检见检材中待检区域打印字迹与捺印指纹交叉重叠部位均为捺印指纹的印油覆盖于打印字迹的墨迹之上，印字交叉部位打印字迹墨迹表面色泽变亮，且打印字迹上侧存在大面积暗红色附着物粘附，经剥离检验后发现其打印字迹下侧未见存在指纹油墨；符合先字后印的特征，由此可以认定是打印字迹形成在先，捺印指纹形成在后（详见附图）。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/22-1.png')">
      </div>

      <p class="space_content" >
        鉴定分析：
      </p>
      <p class="space_content" >
        检材中待检区域印字交叉部位完整、无影响检验效果的污染、破坏痕迹，印字交叉部位的显微特征反映明显，特征质量高，已充分表现出先字后印的特征特点。
      </p>
      <p class="space_content" >
        鉴定结果：
      </p>
      <p class="space_content" >
        依委托方所提供的现有材料，认为送检的落款日期为“2015年7月17日”的《收条》中的捺印指纹与打印字迹交叉重叠部位为打印字迹形成在先，捺印指纹形成在后。
      </p>
      <p class="space_content" >
        法院根据福建历思司法鉴定所泉州分所提供的司法鉴定意见书，认定借款收条是真实的，依法判令被告卢某在限期内立即偿还原告杜某的借款本金及利息。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/22-2.png')">
      </div>
      <p class="space_content" >
        此朱墨时序鉴定的案例，评选上了中国法律服务网，被作为司法行政（法律服务）的典型案例收录在案例库中了！
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/21'"><p class="ellipsis1">典型案例来了！高处坠物工伤事故的伤残等级鉴定</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/23'"><p class="ellipsis1">号外，号外，我们又和一所高校握手了！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
